
import { Vue, prop } from "vue-class-component";

class Props {
  fill = prop<boolean>({
    default: false,
    type: Boolean
  });
}
export default class Track extends Vue.with(Props) {}
